import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import Logo from "../components/logo"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Premier Underground</h1>
    <h2>Who We Are</h2>
    <p>
      We are an underground cable boring company. We are family owned and run.
      We have over X years of combined experience in the cable industry.
    </p>
    <h2>What We Do</h2>
    <p>We provide premier communication services, and we do it with pride.</p>
    <h2>What We Offer</h2>
    <p>
      We offer a premier service: delivering communication solutions. We don't
      just try to get the job done. We get the job done right, and we get it
      right the first time.
    </p>
  </Layout>
)

export default IndexPage
